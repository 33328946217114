export default {
  BUTTON_PRIMARY_CLASS: 'button button--primary',
  BUTTON_SECONDARY_CLASS: 'button',

  SEARCH_BOX_INPUT_CLASS: 'form-input',
  SEARCH_BOX_CLEAR_BUTTON: '{window.innerWidth > 801 ? "✕" : "Clear"}',

  GARAGE_TITLE: '',
  GARAGE_SIZE: '<span class="garage-size countPill" key="garage-size">{{size}}</span>',

  SELECT_YOUR_VEHICLE: 'Search by Vehicle',

  FILTERS_TEXT: 'Show Filters',
  FILTERS_ICON: `<rt-virtual template="icons/angle-down" />`,
  FACET_TITLE_ICON: `
  <rt-virtual rt-if="this.isCollapsed" template="icons/facet-plus" />
  <rt-virtual rt-if="!this.isCollapsed" template="icons/facet-minus" />`,
};
