//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-956:_3952,_8344,_8276,_7876,_4744,_9843,_556,_7300;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-956')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-956', "_3952,_8344,_8276,_7876,_4744,_9843,_556,_7300");
        }
      }catch (ex) {
        console.error(ex);
      }