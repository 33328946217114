//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-956:_8024,_3188,_8128,_6556,_552,_3432,_1928,_2756;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-956')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-956', "_8024,_3188,_8128,_6556,_552,_3432,_1928,_2756");
        }
      }catch (ex) {
        console.error(ex);
      }