const baseFitmentFields = ['Year', 'Make', 'Model', 'Engine'];
const extraFitmentFields = [];

export default {
  platform: 'bigcommerce',
  SearchRequestDefaults: {
    pageSize: 36,
  },
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#cm-results',
      template: 'SearchPage',
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: {
        selector: '.pages-category > .container > .page',
        class: 'page-content',
      },
      template: 'SearchPage',
    },
    {
      name: 'BrandPage',
      type: 'SearchPage',
      location: { replace: '.pages-brand > .container > .page', class: 'page' },
      template: 'SearchPage',
    },
    {
      name: 'SearchHeader',
      sortSelectClass: 'form-select',
      sortEntries: {
        'relevance': 'Best Match',
        'date_created:desc': 'Newest',
        'name': 'A to Z',
        'name:desc': 'Z to A',
        'price': 'Price: Low - High',
        'price:desc': 'Price: High - Low',
      },
    },
    {
      name: 'FilterChips',
      type: 'FacetPanel',
    },
    {
      name: 'FacetPanel',
      ignoreFields: ['transmission_filter', 'gear_filter'],
    },
    {
      name: 'TransmissionGearFacetPAnel',
      type: 'FacetPanel',
      fields: ['transmission_filter', 'gear_filter'],
    },
    { name: 'SearchResult' },
    {
      name: 'SearchBox',
      location: { selector: 'header .header-search > .container', class: 'container' },
      template: 'SearchBox',
    },
    {
      name: 'SearchBoxDialogButton',
      location: '#cm-mobile-search-box',
      template: 'searchBox/dialogButtonMobile',
    },
    {
      name: 'Garage',
      location: '#cm-garage',
      template: 'fitmentSearch/garage',
    },
    {
      name: 'MobileGarage',
      type: 'Garage',
      location: { selector: '#cm-garage-mobile', class: 'navUser-action cm_garage__mobile' },
      template: 'fitmentSearch/garage',
    },
    {
      name: 'HomeVehicleWidget',
      type: 'VehicleWidget',
      location: '#cm-home-ymm',
      template: 'fitmentSearch/collapsibleVehicleWidget',
      columnBreakpoint: 800,
    },
    {
      name: 'SearchVehicleWidget',
      type: 'VehicleWidget',
      isAlwaysActive: true,
      isAlwaysColumnLayout: true,
    },
    {
      name: 'DialogVehicleWidget',
      type: 'VehicleWidget',
    },
    {
      name: 'VerifyFitment',
      type: 'VehicleWidget',
      location: { insertBefore: '.productView-details .productView-options' },
      template: 'fitmentSearch/verifyFitment',
    },
    {
      name: 'FitmentTable',
      location: '.productView-description #tab-coverage',
      template: 'fitmentSearch/fitmentTable',
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
    {
      name: 'vehicle-dialog',
      type: 'ContextDialog',
      template: 'fitmentSearch/dialog',
    },
  ],
};
