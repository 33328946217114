//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-956:_16,_1103,_756,_236,_7024,_6472,_6380,_9912;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-956')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-956', "_16,_1103,_756,_236,_7024,_6472,_6380,_9912");
        }
      }catch (ex) {
        console.error(ex);
      }